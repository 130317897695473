let data = [
  //Tender
  {
    path: "/vendor/tender",
    component: () => import("@/pages/Praveen/Vendor/Tender/list.vue"),
    meta: {
      layout: "home",
      fortype: "vendor",
    },
  },
  {
    path: "/vendor/tender/:id/questions",
    name: "vendor-tender-qanswer",
    component: () => import("@/pages/Praveen/Vendor/Tender/qanswer.vue"),
    meta: {
      layout: "home",
      fortype: "vendor",
    },
  },
  {
    path: "/vendor/tender/:id/worklist",
    name: "vendor-tender-worklist",
    component: () => import("@/pages/Praveen/Vendor/Tender/worklist.vue"),
    meta: {
      layout: "empty",
      fortype: "vendor",
    },
  },
  {
    path: "/vendor/tender/:id",
    name: "vendor-tender-view",
    component: () => import("@/pages/Praveen/Vendor/Tender/view.vue"),
    meta: {
      layout: "home",
      fortype: "vendor",
    },
  },
  // {
  //   path: "/vendor/tender/worklist/:id",
  //   name: 'vendor-tender-worklist',
  //   component: () => import("@/pages/Praveen/Vendor/Tender/Components/worklist.vue"),
  //   meta: {
  //     layout: "home",
  //   },
  // },
  // {
  //   path: "/vendor/tender/edit/:id",
  //   name: 'vendor-tender-edit',
  //   component: () => import("@/pages/Praveen/Vendor/Tender/manage.vue"),
  //   meta: {
  //     layout: "home",
  //   },
  // },
];

export default data;
