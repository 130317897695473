<template>
  <div class="m-0">
    <main class="" style="background: #f0f4f7">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  props: ["user"],
  computed: {},
  methods: {},
};
</script>
