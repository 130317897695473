import axios from "axios";
import router from "@/router";
import { notify } from "@kyvg/vue3-notification";
axios.interceptors.request.use((config) => {
  let token = JSON.parse(localStorage.getItem("token"));
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
axios.defaults.baseURL = "https://etenderapp.techvoyager.in/api/";
axios.interceptors.response.use(
  function (response) {
    if (response) {
      if (response.status === 401) {
        return router.push("/signin/buyer");
      } else if (response.status === 403) {
        return router.push(response.data.route);
      }
      if (
        Object.prototype.hasOwnProperty.call(response.data, "message") &&
        response.data.message
      ) {
        notify({
          type: response.data.success == 0 ? "error" : "success",
          title: response.data.message.title || "",
          text: response.data.message.message || "",
        });
      }
      if (
        Object.prototype.hasOwnProperty.call(
          response.data,
          "specialredirect"
        ) &&
        response.data.specialredirect != "special" &&
        Object.prototype.hasOwnProperty.call(response.data, "redirectto")
      ) {
        this.$router.push(response.data.redirectto);
      }
    }
    return response;
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === 401) {
        return router.push("/signin/buyer");
      } else if (error.response.status === 403) {
        return router.push(error.response.data.route);
      }
    }
  }
);

export default axios;
